:root {
    --title-font: "Oxygen", sans-serif;
    --description-font: "Poppins", sans-serif;
    --sub-title-font: "Montserrat", sans-serif;
}

header .container {
    /* max-width: 1920px !important; */
    padding: 0 10px;
    overflow: hidden;

}
/* #dropdown-language-button{
    padding-right: 0px;
} */
header {
    /* overflow: hidden; */
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.navbar-brand img {
    max-width: 100px;
}

.nav-link {
    font-size: 16px !important;
}

header .from_input {
    border: 1px solid #A3A3A3 !important;
}

header .from_input:focus-visible {
    outline: none;
}

header .from_input:focus {
    box-shadow: none !important;
    border-color: #A3A3A3 !important;
}

.collapse {
    visibility: visible;
}

header form button {
    background-color: #3A5E5D !important;
    top: 0px;
    left: 309px;
    width: 47.17px;
    height: 42px;
    z-index: 2;
}

.dropdown:focus-visible {
    outline: none;
}

.btn:hover {
    border-color: transparent !important;
}

.btn.show {
    border-color: transparent !important;
}

.dropdown-menu {
    border: none !important;
    background-color: transparent !important;
}

header .form-select {
    border: none !important;
}

header .form-select:focus {
    box-shadow: none !important;
    border-color: #2125291b !important;
}

.banner_section {
    width: 100%;
    height: 100%;
}

.admin_using .admin_icon {
    width: 18px;
    height: 20px;
}

.admin_icon2 {
    width: 20px;
    height: 20px;
}

.account_sign {
    font-size: 12px;
}

.text_bold {
    font-size: 15px;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.nav-item .nav-link {
    color: #292929;
    font-family: var(--sub-title-font);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}


/* change */
.cuntry_img,
.cuntry_main_menu button,
.login_act button {
    border: none;
    background-color: transparent;
}

.header_box_menu .from_input {
    height: 42px;
}

.dropdown button h6 {
    font-size: 14px;
}

/* mobile-size */
.from_input_mobile:focus-visible {
    outline: none;
}

.from_input_mobile:focus {
    box-shadow: none !important;
}

.offcanvas {
    width: 700px !important;
}

.offcanvas-body ul li {
    list-style: none;
}

.offcanvas-body ul li a {
    text-decoration: none;
    color: #000;
}

.btn-close {
    content: '';
    top: -18%;
    left: 100%;
    width: 20px !important;
    height: 20px !important;
    filter: invert(100) !important;
}

.close-btn {
    filter: invert(0) !important;
}

.btn-close:focus {
    box-shadow: none !important;
}

.modal-dialog {
    height: 85% !important;
}

.modal-body a {
    text-decoration: none;
    color: #000;
}

/* Custom CSS for the header layout */
.mobile-screen .navbar-toggler {
    order: 1;
}

.mobile-screen .navbar-brand {
    order: 2;
    text-align: center;
}

.mobile-screen .header_box_menu {
    order: 3;
    display: flex;
    align-items: center;
}






/* extra chatgpt*/
/* Ensure the dropdown opens below the header */
.navbar-collapse {
    position: relative;
    z-index: 1;
    animation: slideDown 0.3s ease-in-out;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
.navbar-toggler:focus {
    box-shadow: none;
}

/* Style for customprofiledown */
.customprofiledown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Ensure all icons have the same size and weight */
.icon {
    font-size: 1.25rem;
    /* Adjust size as needed */
    font-weight: 400;
    /* Adjust weight as needed */
}

.header_box_menu .bi,
.header_box_menu .search-icon,
.header_box_menu .dropdown .bi {
    font-size: 15px;
    /* Ensuring all icons have the same size */
    font-weight: 400;
    /* Ensuring all icons have the same weight */
}


/* extra  */
#dropdown-country-button,
#dropdown-language-button {
    background-color: transparent;
    border: none;
    color: #000;
}

.dropdown-menu {
    background-color: #ffffff !important;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
    z-index: 99;
}

.account_sign {
    color: #000 !important;
}



@media (min-width:375px) {
    .container {
        max-width: 100%;
        padding: 0px 10px;
    }

    header .from_input {
        width: 330px;
    }

    .search-iconbox {
        background-color: transparent;
        color: #000;
    }

    header form button {
        left: 387px;
    }

    header .py-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pt-50 {
        padding-top: 50px;
    }

    .pb-50 {
        padding-bottom: 50px;
    }

    .navbar-brand img {
        max-width: 80px;
    }

    .customprofiledown {
        top: 100%;
        left: 0;
        width: 15%;
    }

}

@media (max-width:575px) {
    .container {
        max-width: 100%;
        padding: 0px 10px;
    }

    header .from_input {
        width: 330px;
    }

    header form button {
        left: 289px;
    }

    .py-50 {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .pt-50 {
        padding-top: 50px;
    }

    .pb-50 {
        padding-bottom: 50px;
    }

    .navbar-brand img {
        max-width: 150px;
    }

    .customprofiledown {
        top: 94%;
        left: 33%;
        width: 65%;

    }

    #dropdown-country-button,
    #dropdown-language-button {
        padding-left: 0px;
    }
    .search-container {
        max-width: 43px !important;
        position: relative;
        width: 50%;
    }
    
    
}

@media (min-width:576px) {
    .container {
        max-width: 100%;
        padding: 0px 10px;
    }

    header .from_input {
        width: 190px;
    }

    header form button {
        left: -15px;
        width: 44.17px;
        height: 24px;
    }

    .navbar-brand img {
        max-width: 150px;
    }

    .from_input::placeholder {
        font-size: 12px;
    }

    .header_box_menu .from_input {
        height: 32px;
    }
}

@media (min-width:768px) {
    .container {
        max-width: 720px;
        padding: 0px 10px;
    }

    header .from_input {
        width: 260px;
    }

    header form button {
        left: 215px;
        height: 32px;
        width: 44.17px;
    }

    /* .navbar-brand img {
        max-width: 80px;
    } */

    .from_input_mobile {
        width: 260px;
        font-size: 14px;
    }

    .navbar-brand img {
        max-width: 80px;
    }

    .offcanvas {
        width: 700px !important;
    }

    .from_input::placeholder {
        font-size: 12px;
    }

    .header_box_menu .from_input {
        height: 32px;
    }
}

@media (min-width:992px) {
    .container {
        max-width: 960px;
    }

    .btn {
        top: 1px;
        left: 301px;
    }

    header .from_input {
        width: 190px;
    }

    header form button {
        left: 183px;
        width: 37.17px;
        height: 30px;
    }

    .nav-link {
        font-size: 10px !important;
    }

    header .from_input::placeholder {
        font-size: 10px;
    }

    /* .navbar-brand img {
        max-width: 70px;
    } */

    .login_act span {
        font-size: 12px;
    }

    .py-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pt-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .pb-100 {
        padding-bottom: 100px;
    }

    .navbar-brand img {
        max-width: 85px;
    }

    .header_box_menu .from_input {
        height: 32px;
    }

    .from_input::placeholder {
        font-size: 16px;
    }

    .header_box_menu .from_input {
        height: 30px;
    }

    .customprofiledown {
        top: 79%;
        left: 84%;
        width: 15%;
    }
    .search-container {
        max-width: 300px !important;
        position: relative;
        width: 50%;
    }
    
    
}

@media (min-width:1200px) {
    .container {
        max-width: 1140px;
    }

    .btn {
        top: 1px;
        left: 301px;
    }

    header .from_input {
        width: 280px;
    }

    header form button {
        left: 280px;
        height: 38px;
        width: 47.17px;
    }

    .nav-link {
        font-size: 12px !important;
    }

    header .from_input::placeholder {
        font-size: 12px;
    }

    .navbar-brand img {
        max-width: 150px;
    }

    .login_act span {
        font-size: 15px;
    }

    .header_box_menu .from_input {
        height: 38px;
    }

    .customprofiledown {
        top: 79%;
        left: 84%;
        width: 15%;
    }
}

@media (min-width:1400px) {
    .container {
        max-width: 1320px;
    }

    header .container-fluid {
        margin: 0px 40px;
        /* overflow: hidden; */

    }

    header .form-control {
        width: 300px !important;
    }


    .btn {
        top: 1px;
        left: 301px;
    }

    header .from_input {
        width: 350px;
    }

    header form button {
        left: 350px;

    }

    .nav-link {
        font-size: 14px !important;
    }

    header .from_input::placeholder {
        font-size: 16px !important;
    }
}

@media (min-width:1920px) {
    .container {
        max-width: 1770px !important;
    }

    header .container-fluid {
        margin: 0px 80px;
        /* overflow: hidden; */

    }

    header .from_input {
        width: 400px;
    }

    header form button {
        left: 400px;
    }
    .customprofiledown {
        top: 79%;
        left: 88%;
        width: 9%;
    }
}
@media (min-width:2550px) {
    .customprofiledown {
        top: 79%;
        left: 90%;
        width: 7%;
    }
}
@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: center !important;
    }
}



.navbar-collapse {
    flex-grow: 0 !important;
}







/* Add spacing and styles for the header */
.header-spacing {
    padding: 0 15px;
    /* Adjust padding as needed */
}

/* Style for the search input */
.search-container {
    position: relative;
    width: 46%;
    max-width: 300px;
    /* Adjust as needed */
}

.search-input {
    width: 95%;
    padding: 5px 51px 5px 50px;
    border: 1px solid #b20570;
    border-radius: 4px;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust positioning */
    transform: translateY(-50%);
    color: #ffffff;
    /* Adjust color */
}

.header-menu {
    display: flex;
    align-items: center;
}

.search-iconbox {
    width: 35px;
    height: 35px;
    background-color: #b20570;
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    color: #999;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}


/* Mobile menu styling */
.mobile-menu {
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    width: 100%;
    left: 0;
    top: 60px;
    /* Adjust based on your header height */
    padding: 20px;
    border-radius: 0 0 8px 8px;
}

.mobile-icons a {
    display: flex;
    align-items: center;
    padding: 5px 10px;
}

.mobile-icons img {
    width: 24px;
    height: 24px;
}