input[type="radio"]:checked {
  background-color: #b20570; /* Checked state color */
  border-color: #fffcfc;
}

.form-check-input:active {
  filter: brightness(100%);
}
.form-check-input:focus {
  box-shadow: none !important;
}
.buy-now-modal .modal-content {
  border-radius: 15px;
}

.buy-now-modal .modal-header {
  border-bottom: none;
  /* padding: 20px 30px; */
}

.buy-now-modal .modal-body {
  /* padding: 0 30px 20px; */
}

.buy-now-modal .modal-footer {
  border-top: none;
  /* padding: 20px 30px; */
}
.color{
  color:rgb(178,5,112)
}
.product-details {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.product-image {
  flex: 1;
  min-width: 300px;
}

.product-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-info {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  /* Each cart item container */
  .cart-item {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #fff;
    transition: box-shadow 0.3s ease;
  }
  
  /* Hover effect on cart items */
  .cart-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* Product name styling */
  .cart-item-name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Product detail (quantity, price, weight) */
  .cart-item-detail {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
  }
  
  .cart-item-detail strong {
    color: #000;
  }

.product-info h2 {
  /* margin-bottom: 10px; */
  font-size: 24px;
}

.product-info .price {
  font-size: 20px;
  font-weight: bold;
  color: #e91e63;
  /* margin-bottom: 15px; */
}
.paybtn {
  background-color: #e91e63 !important;
  border-color: aliceblue !important;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .product-info .description {
    margin-bottom: 15px;
  } */

/* .product-info p {
    margin-bottom: 5px;
  }
   */
@media (max-width: 768px) {
  .product-details {
    flex-direction: column;
  }

  .product-image,
  .product-info {
    width: 100%;
  }
}
