/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.sellers .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #fff;
  /* border-bottom: 1px solid; */
  background:#b20570;
  border-radius: 5px;
}
#root {
  overflow-x: hidden;
  user-select: none;
  /* For most modern browsers */
  -webkit-user-select: none;
  /* For Safari */
  -moz-user-select: none;
  /* For Firefox */
  -ms-user-select: none;
  /* For IE/Edge */
}
.sticky-top {
  top: -1px !important;
}
.carousel-control-next,
.carousel-control-prev {
  width: 2.5% !important;
}

body .text-primary {
  color: var(--button-color) !important;
  font-weight: 700 !important;
}

:root {
  --primary-color: #6C7275;
  --primary-color-rgb: 108, 114, 117;
  --black-color: #141718;
  --black-color-rgb: 20, 23, 24;
  --text-color: #a3a3a3;
  --text-color-rgb: 163, 163, 163;
  --title-font: "Oxygen", sans-serif;
  --description-font: "Poppins", sans-serif;
  --sub-title-font: "Montserrat", sans-serif;
  --button-color: #b20570;
  /* --button-color: #e5097f; */
  --button-color-rgb: 178, 5, 112;
}

body {
  font-family: url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3 !important;
}

.title h4 {
  color: var(--black-color);
  font-family: var(--title-font);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: capitalize;
}

.title button {
  padding: 8px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 50px;
  background-color: var(--button-color);
  border: none;
  color: #FFF;
}

p {
  color: #000;
  font-family: var(--description-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 0;
}


.most-popular-section {
  overflow: hidden;
}

.slick-dots {
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.slick-dots li button:before {
  color: black;
}

.slick-dots li.slick-active button:before {
  color: black;
}

.sellerdiv{
  cursor: pointer;
}

.search-container {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.search-input {
  width: 100%;
  padding-right: 35px;
  border-radius: 25px;
  border: 1px solid #ccc;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #888;
}

.review-card {
  background-color: #F5F5F5;
  min-height: 222px;
    height: 100%;
}
#dropdown-country-button{
  padding-left: 0px !important;
}

.product-review{
  max-height: 150px;
  overflow-y: scroll;
}
.subcategorymenu{
  text-decoration: none !important;
} 
@media (max-width: 767px) {
  .slick-dots {
    bottom: -20px;
  }

  .title h4 {
    font-size: 20px;
  }

  .service .card .card-body {
    padding: 16px 5px 0px !important;
  }
}

@media (min-width: 768px) {
  .service .card .card-body {
    padding: 10px 10px 0px !important;
  }
}

.slick-slide {
  padding: 0 5px;
}

.slick-dots {
  bottom: -30px;
}

.container {
  overflow: hidden;
}

.similarproductimage {
  margin: 0 auto;
}

.topselling .similarproductimage {
  margin: 0;
}

.desktop-view .similarproductimage {
  margin: 0;
}

.btn-close {
  background: white var(--bs-btn-close-bg) center / 1em auto no-repeat !important;
}




/* Navbar */
.navbar {
  background: var(--button-color);
}

/* .navbar p {
  color: #000;
  font-family: var(--description-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 0;
} */

/* service */
.service .card {
  background: #F3F5F7;
  border: none;
  border-radius: 0px;
}

/* .service h4 {
  color: var(--black-color);
  font-family: var(--sub-title-font);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0;
} */

/* .service p {
  color: var(--primary-color);
  font-family: var(--description-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
} */
/* Navbar.css */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 40px;  */
  padding: 20px !important;
  background-color: var(--button-color);
  /* Adjust background color as needed */
  /* overflow: hidden;  */
  color: #fff;
}

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.slide-item {
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  /* Smooth transition for fade effect */
}

.slide-item.active {
  opacity: 1;
}


.animate-text {
  animation: slideIn 15s linear infinite;
}

#product-tabs-tabpane-description p {
  text-transform: capitalize;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

#product-tabs-tabpane-description p:last-child {
  padding-top: 0px;
}

#product-tabs-tabpane-description p {
  font-size: 14px;
}

.text-muted {
  font-size: 16px !important;
}

.search-input:focus-visible {
  outline: none;
}

/* sellers */
.sellers .sellersmenu li p {
  padding: 10px 30px;
  background:#A3A3A313;
  margin:0 5px;
  border-radius:5px;
  color: #A3A3A3;
  /* font-family: "Nunito Sans"; */
  font-size: 18px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.testimonial .custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.testimonial .next-arrow {
  right: -20px;
}

.testimonial .prev-arrow {
  left: -20px;
}

.testimonial {
  position: relative;
}

.review-rating svg {
  width: 20px;
  height: 20px;
}

.review-section .slick-next,
.review-section .slick-prev {
  display: block !important;
  color: transparent;
  top: 40%;
  background-color: transparent;
}

.review-section .slick-dots {
  display: none !important;
}

@media (max-width: 768px) {
  .testimonial .next-arrow {
    right: 10px;
  }

  .testimonial .prev-arrow {
    left: 10px;
  }

  .decs h2 {
    font-size: 18px;
  }

  .search-input2 {
    width: 80% !important;
  }

  .service .card .card-body h4 {
    font-size: 10px !important;
  }

  .dropdown-menu2[data-bs-popper] {
    left: -76px !important;
  }

  .review-section .slick-next {
    right: -6px !important;
  }

  .review-section .slick-prev {
    left: -16px !important;
    z-index: 1;
  }

  .review-section .slick-prev:before,
  .slick-next:before {
    color: transparent !important;
  }

  .navbar-toggler {
    font-size: 16px !important;
    border: none !important;
    padding-left: 0px !important;
  }
}

/* .similarproductimage .selling-img video, .similarproductimage .selling-img img */
.customslidemain .slick-slide {
  width: 210px !important;
}

.customslidemain .slick-slider .slick-track,
.customslidemain .slick-slider .slick-list {
  margin-left: 0px;
}

.customslidemain2 .slick-slider .slick-track,
.customslidemain2 .slick-slider .slick-list {
  margin-left: -21px !important;
}

.plusicon svg {
  width: 20px;
}

.photosection img {
  height: 100%;
}

/* .sellers {

  overflow: hidden;
} */

/* .sellers .position-relative {
  overflow: hidden; 
}

.sellers img {
  transform: scale(1);
  transition: transform 0.3s; 
}

.sellers img:hover {
  transform: scale(1.1); 
} */
.selerbtn {
  background-color: var(--button-color);
  cursor: pointer;
  color: rgb(255, 255, 255);
  /* border: 1px solid black; */
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-family: var(--text-font-family);
  font-weight: var(--text-font-bold-weight);
  letter-spacing: 1.5px;
  background-image: linear-gradient(178deg, var(--button-color), var(--button-color) 10%, #00000012 10% 100%), linear-gradient(var(--button-color), var(--button-color));
  background-position: 100% -100%, 100% 100%;
  background-repeat: no-repeat;
  background-size: 100% 200%, 100% 100%;
  transform: translateZ(0);
}

@media screen and (pointer: fine) {
  .selerbtn:hover {
    background-color: rgb(230, 230, 230);
    color: rgb(255, 255, 255);
    background-position: 100% 25%, 100% 100%;
  }
}


/* .sellers h4 {
  color: #0C0C0C;
  font-family: var(--title-font);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-transform: capitalize;
} */

.seller-img img {
  width: 100%;
}

.seller .seller-img {
  height: 100%;
}

/* topselling */
/* .topselling h4 {
  color: #0C0C0C;
  font-family: var(--title-font);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; 
  text-transform: capitalize;
} */

.selling-text h5,
.testimonial-text h5 {
  color: var(--black-color);
  font-family: var(--sub-title-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0;
}

/* .selling-text p,
.testimonial-text p {
  color: var(--text-color);
  font-family: var(--description-font);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; 
} */

.selling-rating .s-price span {
  color: var(--black-color);
  font-family: var(--description-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 122.222% */
}

.topsellingbtn button {
  display: inline-flex;
  height: 52px;
  padding: 16px 54px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 50px;
  border: none;
}

/* testimonial */
.testimonial-text p {
  line-height: 22px;
}

/* .testimonial h4 {
  color: #0C0C0C;
  font-family: var(--title-font);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; 
  text-transform: capitalize;
} */

/* Ongoings */
/* .ongoing-button{
padding: 8px 12px;
align-items: flex-start;
gap: 10px;
border-radius: 50px;
background-color: var(--black-color);
border: none;
color: #FFF;
} */

.Ongoings .ongoings-img img {
  border-radius: 20px;
  height: 275px;
  width: 100%;
  object-fit: cover;
}

.ongoing-detail h5 {
  color: var(--black-color);
  font-family: var(--sub-title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

.ongoing-detail ul li {
  color: #404040;
  font-family: var(--sub-title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 25.6px */
}

/* Frequently Asked Questions */
/* .faq-title h4{
  color:var(--black-color);
text-align: center;
font-family: var(--title-font);
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
} */
/* 
.faq-title p{
  color: #666;
font-family: var(--description-font);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 160%;  
} */

/* .faq-title button{
  background-color: var(--black-color);
 display: flex;
padding: 8px 12px;
align-items: flex-start;
gap: 10px;
color: #FFF;
border-radius: 50px;
border: none;
} */


/* productreview */
/* .productreview {
  background: #F8F8F8;
} */

.product-size h6,
.collection-size h6 {
  color: #414141;
  font-family: var(--title-font);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170.52%;
  /* 23.873px */
}

/* similarproduct */
.similar-price .cancelprice {
  color: #404040;
  font-family: var(--title-font);
  font-size: 14px;
  font-weight: 400;
}

.similar-price .fixedprice {
  color: #000;
  font-family: var(--title-font);
  font-size: 14px;
  font-weight: 600;
}

.similarproductimage .discountnumber {
  display: inline-flex;
  padding: 0px 8px;
  border-radius: 5px;
  position: absolute;
  background: #3D3D3D;
  top: 20px;
  left:20px;
}

.discountnumber p {
  color: #fff !important;
  padding: 2px 0px;
}

/* footer */
footer {
  background: #F0F0F0;
}

footer h5 {
  color: var(--black-color);
  font-family: var(--sub-title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

/* footer p {
  color: rgba(0, 0, 0, 0.60);
  font-family:  var(--description-font); 
  font-size: 14px;
  line-height: 22px; 
} */

footer ul {
  padding: 0;
  list-style: none;
}

footer ul li a {
  color: rgba(0, 0, 0, 0.60);
  font-family: var(--sub-title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  text-decoration: none;
}

footer ul li {
  color: #00000099;
  padding: 5px 0;
  text-transform: capitalize;
}

.mobile-screen .accordion-item {
  border: none;
}

.mobile-screen .accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.mobile-screen .accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: transparent;
  box-shadow: none;
}

.mobile-screen .accordion-button {
  background: transparent;
}


/* accordion-faq */

.accordion-faq button {
  background-color: transparent !important;
  border-top: 1px solid #A6A6A6;
  border-color: #A6A6A6;
  border-radius: 0px !important;
}


/* .accordion-item:first-of-type>.accordion-header .accordion-button{
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
} */
.accordion-faq .accordion-button:focus {
  box-shadow: none !important;
}

/* photosection  */
/* photosection  */
.photosection {
  background: #edecea;
}

/* .centersmallimage img {
  max-height: 332px;
} */

.photosection img {
  width: 100%;
  /* height: auto; */
  height: 100% !important;
  object-fit: cover;
}

.photosection-img {
  height: 235px;
}

.productreview .nav {
  margin-bottom: 18px;
}

.productreview .tab-content p {
  line-height: 22px;
}

.form-select:focus {
  border-color: #b20570;
  outline: 0;
  box-shadow: 0 0 0 0.10rem rgb(178 5 112);
}
@media (max-width:575px) {
  .plusicon svg {
    width: 15px;
  }
  .photosection-img {
    height: 170px !important;
  }

  .similarproductimage .discountnumber {
    /* padding: 7px 13px;
    top: 11px;
    left: 14px; */
    padding: 0px 5px;
    top: 11px;
    left: 4px;
  }

  .service .card .card-body .card-text {
    font-size: 9px;
  }
}

@media (min-width:576px) {
  .photosection-img {
    height: 170px !important;
  }

  .similarproductimage .discountnumber {
    padding: 7px 13px;
    top: 11px;
    left: 14px;
  }

  .service .card .card-body .card-text {
    font-size: 10px;
  }
}

@media (min-width:768px) {
  .photosection-img {
    height: 175px !important;
  }

  .similarproductimage .discountnumber {
    padding: 0px 8px;
    top: 5px;
    left: 10px;
  }

  .service .card .card-body .card-text {
    font-size: 14px;
  }
}

@media (min-width:992px) {
  .photosection-img {
    height: 235px !important;
  }

  .similarproductimage .discountnumber {
    left: 9px;
    padding: 0 8px;
    top: 5px;
  }

}

/* add by ronik start */
.btn_box_menu {
  font-size: 25px;
}

/* add by ronik end */
/* product-slider */
.product-slider {
  overflow: hidden;
}

.addtocart {
  background-color: var(--button-color) !important;
  border: none !important;
}

/* ourcollection */
.DocSearch {
  width: 100%;
  padding: 8px 8px 8px 20px;
  justify-content: space-between;
  align-items: center;
  border-radius: 42px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(11px);
  background-color: transparent;
}

.search-input2 {
  width: 95%;
  border: none;
  padding: 0px;
  /* border: 1px solid #b20570; */
  border-radius: 4px;
}

.search-input2:focus-visible {
  outline: none
}

.search-button {
  border-radius: 24px;
  background: #666;
  padding: 8px;
  align-items: flex-start;
}

.desc {
  color: var(--black-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.description-text {
  color: var(--text-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.main-collection {
  position: relative;

}

.main-collection .card {
  position: relative;
}

.main-collection .card h5::before {
  position: absolute;
  content: '';
  width: 3px;
  height: 18px;
  background-color: var(--black-color);
  top: 12px;
  left: 10px;

}


.slick-next,
.slick-prev {
  display: none !important;
  z-index:99 !important;
}

/* @media (max-width:992px){
  .slick-prev {
    left:-14px !important;
  }
} */


.slick-dots {
  overflow: hidden !important;
}



.main-collection .card .accordion-item {
  border: none !important;
}

.main-collection .card .accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.main-collection .card .accordion-button {
  font-family: var(--title-font);
}

.main-collection .form-check-input:checked {
  background-color: var(--black-color);
  box-shadow: none;
  border: none;
}

.main-collection .form-check-input:focus {
  border-color: var(--black-color);
  outline: 0;
  box-shadow: none;
}

/* loginpage */
.loginpage input {
  border-radius: 50px;
  height: 50px;
}

.loginpage .card-text,
.loginpage .card-text a {
  color: #949494;
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.loginpage .card-text a {
  color: #2B2B2B;
}

.loginpage .submitbtn {
  background-color: var(--button-color);
  border-radius: 50px;
  padding: 12px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.loginpage .submitbtn:hover {
  background-color: var(--black-color);
  /* color: #fff; */
}

.loginpage .card-body {
  padding: 30px 40px !important;
}

.loginpage label {
  color: #3D3D3D;
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

/* signup */
.signup .form-check-input {
  height: 1rem;
}

.signup .form-check label {
  font-size: 14px !important;
}

/* cartpage */
.table {
  --bs-table-striped-bg: transform !important;
}

.cartpayment .card .card-header {
  padding: 25px 40px;
  background-color: #9A9A9A;
  font-family: var(--description-font);
  font-size: 20px;
  font-weight: 600;
  border-bottom: none;
}

.cartpayment .card .card-body table thead {
  background-color: transparent;
}

.cartpayment .card .card-body table thead th,
.cartpayment .card-body h5 {
  border-bottom: 1px dashed #DEDFE1;
  padding-bottom: 50px;
  padding-top: 0;
}

.cartpayment .card-body h5,
.cartpayment .card .card-body table thead th {
  color: var(--black-color);
  font-family: var(--title-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.cartpayment .card .card-body table tr td,
.cartpayment .card label {
  color: #949494;
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.cartpayment .table>:not(caption)>*>* {
  border-bottom-width: 0px;
}

.thead-padding-bottom {
  padding-bottom: 10px;
  /* Adjust the padding as needed */
}

.cartpayment .card .card-body {
  padding: 46px 37px;
}

.cartpayment .card .card-body label {
  padding: 8px;
}

.cartpayment .card table tbody,
.cartpayment .card table tfoot tr {
  border-bottom: 1px dashed #DEDFE1;
}

.cartpayment .card .card-body input {
  width: 30px;

}

.cartpayment .card .card-body .form-check input:checked {
  width: 1.25em;
  height: 1.25em;
  border: 0.15em solid #000000;
  /* Custom border color */
  border-radius: 50%;
  background-color: #AF8260;
  /* Custom background color */
}

hr {
  border-top: 1px dashed #DEDFE1 !important;
  opacity: 1 !important;
}

.cartpayment .modal-body {
  padding: 2rem 3rem;
}



/* dropdown */
.customprofiledown {
  position: absolute;
  background: white;
  right: 28px;
  top: 100px;
  list-style: none;
  padding: 10px 20px;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.15);
  /* border-top-left-radius: 20px;
  border-bottom-right-radius: 20px; */
}

.customprofiledown a:hover {
  color: var(--button-color);
}

.customdropdown {
  position: absolute;
  background: white;
  right: 13%;
  top: 15%;
  padding: 10px 20px;
  list-style: none;
}

/* .bannerimg {
  height: 100vh;
} */

/* cart */
.table thead {
  background-color: #9A9A9A;
}

.addtocartbtn {
  width: 100%;
  background-color: var(--button-color);
  color: white;
}

/* personalinformation */

.form-container h2 {
  /* background-color: #9A9A9A;
  width:50%; */
  font-family: var(--sub-title-font);
  font-size: 20px;
}

.form-personal h2 {
  background-color: #9a9a9a;
  color: #fff;
  width: 100%;
  height: 50%;
}

.form-card {
  border: 1px solid #DEDFE1;
}

.form-personal .card .card-header {
  padding: 23px 49px;
  color: #fff;
  background-color: #9A9A9A;
}

.btn-proceed {
  padding: 8px 12px;
  border-radius: 64px;
  background-color: #AF8260;
  color: #fff;
}

/* blog */
.mainimage img {
  border-radius: 29px;
  object-fit: cover;
  height: 700px;
}

.product-data h3 {
  color: #404040;
  font-family: var(--title-font);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  /* 155% */
}

.product-data span {
  color: #666;
  font-family: var(--title-font);
  font-size: 20px;
}

.ongoings-img img {
  border-radius: 20px;
  height: 275px;
  width: 100%;
  object-fit: cover;
}

/* privacy_policy_paragraph */

.privacy_policy_paragraph {
  color: #414141;
  font-family: var(--title-font);
  font-size: 20px;
}

/* offcanvas-body */
.offcanvas-body table tbody tr:hover {
  background-color: var(--button-color);
  color:#fff;
}

.close-btn {
  -webkit-filter: invert(100%);
  /* Safari/Chrome */
  filter: invert(100%);

}

.smallimage img {
  height: 60px;
  margin-bottom: 10px;
}

.mainsliderimg {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.zoom-container {
  /* width: 100%; */
  max-width: 450px;
}




/* cart */
.table thead {
  background-color: #9A9A9A;
}

.addtocartbtn {
  width: 100%;
  background-color: var(--button-color);
  color: white;
}



.somepadding {
  padding: 80px 160px;
}

.table_heading th {
  background: #9A9A9A !important;
  color: #fff !important;
}

.product_heading {
  border-radius: 10px 0 0 !important;
}

tbody span {
  /* background-color: #d9d9d9 !important; */
  height: 60px;
  width: 60px;
}

tbody tr th {
  width: 500px;
}

.border_radius_left {
  border-radius: 0px 0px 10px 10px !important;
}

.radius_right {
  border-radius: 0px 10px 0px 0px !important;
}

.margin_bottom {
  margin-bottom: 70px !important;
}

.somepaddingg {
  padding: 80px 140px;
}

.img_box {
  width: 50px;
  height: 50px;
  background-color: #9A9A9A;
}

.status_clock {
  font-size: 12px;
  background: #6666661F;
  padding: 5px;
  border-radius: 5px;
  color: #707070;
}

.tracking_id {
  color: #6A6A6A;
}

.order_encar {
  color: #6A6A6A;
  text-decoration: none;
}

.similarproductimage .selling-img img {
  width: 100%;
}

.cartscreen .table>:not(caption)>*>* {
  border-bottom-width: 0px !important;
}


/* ProductDetails.css */
.zoom-container {
  position: relative;
  z-index: 10;
}

.main-collection {
  position: relative;
  z-index: 1;
}

.similarproductimage .selling-img video,
.similarproductimage .selling-img img {
  /* height: 300px !important;
  width: 238px !important; */
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  cursor: pointer;
}

/* .similarproductimage .selling-img video,
  .similarproductimage .selling-img img {
    height: 100% !important;
    width: 100% !important;
    border-radius: 7px;
  } */

.plusicon {
  z-index: 99px;
  cursor: pointer;
}

.decs h2 {
  text-transform: capitalize;
}

.smallimage {
  width: 60px !important;
  height: 60px !important;
  object-fit: cover;
}

.smallimage img {
  object-fit: cover;

}

.footer-logo {
  width: 150px;
}

footer .footer-icon li {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #000;
  line-height: 32px;
  transition: all .3s ease-in;
  background-color: transparent;
  cursor: pointer;
  padding: 0px !important;
  text-transform: capitalize;
}

footer .footer-icon li i {
  position: relative;
  left: 8.5px;
  transition: all .3s ease-in;
  font-size: 16px;
}

footer .footer-icon li:hover {
  background-color: #b20570;
  border: 1px solid transparent;

}

footer .footer-icon li:hover i {
  color: #fff;
}

/* footer .footer-icon li .instagram{
  left: 7px;
} */

@media (max-width: 450px) {
  .service .card{
    max-height: 14  0px;
  }
  .service .card-img, .card-img-top{
    height: 25px;
  }
  .service .cardpart { 
    height: 136px;
}
.service .cardpart{
  height: 106px;
}
.sellers{
  padding: 0px;
}
}
@media (max-width: 375px) {
  .cardpart svg {
    height: 20px;
  }
  .service .card{
    max-height: 100%;
      height: 100%;
  }
  .service .card-img, .card-img-top{
    height: 25px;
  }
  .service .card .card-body h4 {
    font-size: 9px !important;
  }
  .service .card .card-body .card-text {
    font-size: 8px;
}
  .zoom-container {
    display: none;
  }
  /* .banner .carousel-inner{
    height: 375px;
  }
  .banner .carousel-inner img{
    height: 375px;
  } */
.t-rating{
  cursor: pointer;
}
  .similarproductimage .selling-img video,
  .similarproductimage .selling-img img {
    height: 100% !important;
    width: 100% !important;
    border-radius: 7px;
  }

  .service .card .card-body h4 {
    font-size: 14px;
  }

}

@media (max-width: 575px) {
  .loginpage .card-body {
    padding: 15px 10px !important;
  }

  .similarproductimage .selling-img video,
  .similarproductimage .selling-img img {
    height: 100% !important;
    width: 100% !important;
    border-radius: 7px;
  }

  /* .selling-text h5, .similar-price .fixedprice, .similar-price .cancelprice{
    font-size: 10px;
  }
  .plusicon svg{
    width: 20px;
    height: 20px;
  }

  .service .card .card-body h4 {
    font-size: 13px;
  } */

  /* .topselling .similarproductimage .selling-img img,
  .topselling .similarproductimage .selling-img video {
    border-radius: 7px;
    height: 260px !important;
    width: 205px !important;
  } */
  .sellers img {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .loginpage .card-body {
    padding: 30px 40px !important;
  }
  footer .footer-icon li i{
    left: 8px;
  }

  .similarproductimage .selling-img video,
  .similarproductimage .selling-img img {
    height: 240px !important;
    /* width: 238px !important; */
    border-radius: 7px;
  }

  .topselling .similarproductimage .selling-img img,
  .topselling .similarproductimage .selling-img video {
    border-radius: 7px;
    height: 195px !important;
    width: 150px !important;
  }

  .service .card .card-body h4 {
    font-size: 16px;
  }

  .sellers img {
    width: 100%;
  }
  .similarproductimage .discountnumber {
    left: 30px;
    padding: 0 8px;
    top: 5px;
  }
}

@media (min-width: 1200px) {

  .topselling .similarproductimage .selling-img img,
  .topselling .similarproductimage .selling-img video {
    border-radius: 7px;
    height: 259px !important;
    width: 173px !important;
  }

  .similarproductimage .discountnumber {
    left: 50px;
    padding: 0 8px;
    top: 5px;
  }
  footer .footer-icon li i{
    left: 8px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px !important;

  }

  .similarproductimage .selling-img video,
  .similarproductimage .selling-img img {
    height: 300px !important;
    /* width: 238px !important; */
    border-radius: 7px;
  }

  .topselling .similarproductimage .selling-img img,
  .topselling .similarproductimage .selling-img video {
    border-radius: 7px;
    height: 300px !important;
    width: 210px !important;
  }

  .service .card .card-body h4 {
    font-size: 20px;
    margin-bottom: 0px !important;
  }

  .cross-icon{
    cursor: pointer;
  }

  .similarproductimage .discountnumber {
    padding: 0 8px;
    top: 5px;
    left: 30px;
  }

  .similarproductimage .commonnumber {
    padding: 0 8px;
    top: 5px !important;
    left: 7px !important;
  }
}

@media (min-width: 1600px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1600px !important;
    padding: 0px 80px !important;
  }
  .centersmallimage img {
    height: 264px;
  }
}

@media (min-width:1920px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1920px !important;
    padding: 0px 80px !important;
  }
  footer .footer-icon li i{
    left: 8px;
  }

  .sellers img {
    width: 100%;
  }

  .similarproductimage .selling-img video,
  .similarproductimage .selling-img img {
    height: 440px !important;
    width: 100% !important;
    border-radius: 7px;
  }

  .centersmallimage img {
    height: 100%;
  }
  .similarproductimage .commonnumber {
    padding: 0 8px;
    top: 10px !important;
    left: 10px !important;
  }

}

@media (min-width:2560px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 2560px !important;
    padding: 0px 80px !important;
  }

  .sellers img {
    width: 100%;
  }

  .similarproductimage .selling-img video,
  .similarproductimage .selling-img img {
    height: auto !important;
    width: 100% !important;
    border-radius: 7px;
  }

  .similarproductimage .commonnumber {
    padding: 0 8px;
    top: 15px !important;
    left: 15px !important;
  }

  .centersmallimage img {
    height: 444px;
  }
}

/* @media (max-width: 575px) {
  .banner .carousel-inner { 
      height: 350px;
  }
} */

header .dropdown-menu[data-bs-popper]{
  left: -40px !important;
}
/* .review-section .slick-slider .slick-list, .review-section .slick-slider .slick-track{
  margin-left: 5px;
} */
