/* Container and general layout */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
  }
  
  /* Title styling */
  h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
  }
  
  /* Order card */
  .order-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    overflow: hidden;
  }
  
  .order-card .header {
    background-color: #f7f7f7;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .order-card .header h2 {
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .order-card .status {
    padding: 4px 12px;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .order-card .status.delivered {
    background-color: #c6f6d5;
    color: #2f855a;
  }
  
  .order-card .status.cancelled {
    background-color: #fed7d7;
    color: #c53030;
  }
  
  .order-card .status.pending {
    background-color: #fefcbf;
    color: #d69e2e;
  }
  
  /* Order items */
  .order-items {
    padding: 16px;
  }
  
  .order-item {
    display: flex;
    border-bottom: 1px solid #e2e8f0;
    padding: 16px 0;
  }
  
  .order-item:last-child {
    border-bottom: none;
  }
  
  .order-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .order-item .item-details {
    flex-grow: 1;
    margin-left: 16px;
  }
  
  .order-item .item-details h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .order-item .item-details p {
    font-size: 0.875rem;
    color: #718096;
  }
  
  /* Shipping and Payment Info */
  .info-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 16px;
    padding: 16px;
  }
  
  .info-section h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .info-section p {
    font-size: 0.875rem;
    color: #4a5568;
  }
  
  /* Order total and action buttons */
  .order-card .order-summary {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e2e8f0;
  }
  
  .order-card .order-summary .total {
    font-size: 1.25rem;
    font-weight: 600;
  }
  
  .order-card .cancel-button {
    background-color: #e53e3e;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .order-card .cancel-button:hover {
    background-color: #c53030;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .order-item {
      flex-direction: column;
      align-items: center;
    }
  
    .order-item img {
      margin-bottom: 8px;
    }
  
    .order-card .order-summary {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .order-card .cancel-button {
      margin-top: 16px;
    }
  }
  