/* Base style for 1920px screen */
.cart_message {
  top: 8px;
  background: #b20570;
  font-size: 12px;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 1.5;
  text-align: center;
}

.sellers .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #fff;
  background: #b20570;

}

.navbar-collapse.show+.overlay {
  display: block;
  opacity: 1;
  /* Show overlay with opacity */
}

.addtocart_fixed_button {
  top: 95% !important;
  z-index: 1000;
  left: 50% !important;
  transform: translate(-50%, -50%);
  width: 50%;
  gap: 20px;
  flex-direction: row;
}



@media (min-width:1920px) {
  .navbar-collapse {
      position: relative;
    }
}
@media (max-width:768px) {
  .navbar-collapse {
    position: absolute;
    top: 0;
  }
}
.addtocart_fixed_button Button {
  width: 100%;
}

.bigscreen_scroll {
  background-color: #fff;
  bottom: 0px;
  z-index: 1000;
  height: 75px;
  box-shadow: 0 -3px 5px rgba(57, 63, 72, 0.3);
}


.collapse.show {
  background-color: #fff;
  width: 100%;
  left: -10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

}



.banner .carousel-inner {
  aspect-ratio: 2/1;
  overflow: hidden;
  object-fit: cover;
}

.service .cardpart {
  aspect-ratio: 3 / 2.2;
  overflow: hidden;
  object-fit: cover;
}

.review-section .review-card {
  aspect-ratio: 3 / 3;
  overflow: hidden;
  object-fit: cover;
}

.title button {
  border: 1px solid transparent;
  background-color: #b20750;
  transition: ease-in-out .5s
}

.title button:hover {
  border: 1px solid #b20750;
  background-color: transparent;
  color: #b20750;
}

.icons {
  font-size: 25px;
}

/* styles.css */
/* .image-style {
  width: 100%;
  border: 2px solid red;
  /* Add other styles here 
}

.default-style {
   Add default styles if needed 
} */
.lazy-load-image-background {
  width: 100%
}

.service {
  padding-top: 50px !important;
  padding-bottom: 25px !important;
}

.sellers {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.feature_product,
.frequentlyaskedquestions {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.photosection {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.Ongoings {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.review-section {
  padding-top: 25px !important;
  padding-bottom: 50px !important;
}

/* 1920px */
@media (max-width: 1920px) {
  .service .cardpart {
    aspect-ratio: 3 / 1;
    object-fit: cover;
    overflow: hidden;
   
  }

  .review-section .review-card {
    aspect-ratio: 3 / 1;
    object-fit: cover;
  }

  .banner .carousel-inner {
    aspect-ratio: 2/1;
    object-fit: cover;
  }
}

/* 1400px */
@media (max-width: 1400px) {
  .service .cardpart {
    aspect-ratio: 3 / 1;
    object-fit: cover;
    overflow: hidden;
  }

  .review-section .review-card {
    aspect-ratio: 3 / 0.3;
    object-fit: cover;
  }

  .banner .carousel-inner {
    aspect-ratio: 2.5/1;
    object-fit: cover;
  }
}

/* 1200px */
@media (max-width: 1200px) {
  .service .cardpart {
    aspect-ratio: 3 / 1.3;
    object-fit: cover;
  }

  .review-section .review-card {
    aspect-ratio: 3 / 1.8;
    object-fit: cover;
  }

  .banner .carousel-inner {
    aspect-ratio: 16/9;
      object-fit: cover;
    }
    
}
 .banner img {
   aspect-ratio: 16/9;
   object-fit: cover;
 }
/* 1200px */
@media (max-width: 992px) {
  .service .cardpart {
    aspect-ratio: 3 / 1.3;
    object-fit: cover;
  }

  .review-section .review-card {
    aspect-ratio: 3 / 0.8;
    object-fit: cover;
  }

  .banner .carousel-inner {
    aspect-ratio: 2.8/1;
    object-fit: cover;
  }

  .reviews .slick-next,
  .reviews .slick-prev {
    display: none !important;
    z-index: 99;
  }
}

/* 768px */
@media (max-width: 768px) {
  .service .cardpart {
    aspect-ratio: 3 / 1.5;
    object-fit: cover;
    height: 166px;
  }

  .review-section .review-card {
    aspect-ratio: 3 / 6;
    object-fit: cover;
  }

  .banner .carousel-inner {
    aspect-ratio: 3/1;
    object-fit: cover;
  }
}

/* 575px */
@media (max-width: 575px) {

  /* Changed to max-width for smaller screens */
  .service .cardpart {
    aspect-ratio: 3 / 2.2;
    /* Adjusted aspect ratio */
    object-fit: cover;
  }

  .review-section .review-card {
    aspect-ratio: 3 / 0.1;
    object-fit: cover;
  }

  .banner .carousel-inner {
    aspect-ratio: 16/9;
    object-fit: cover;
  }

  .service .cardpart {
    height: 106px;
  }

  .sellers {
    padding-top: 0px !important;
  }
}

/* 375px */
@media (max-width: 375px) {
  .service .cardpart {
    aspect-ratio: 3 / 1;
    object-fit: cover;
    height:90px
  }

  .review-section .review-card {
    aspect-ratio: 16/ 9;
    object-fit: cover;
  }
}

/* 320px */
@media (max-width: 320px) {
  .service .cardpart {
    aspect-ratio: 3 / 2.8;
    object-fit: cover;
  }

  .review-section .review-card {
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }

  .banner .carousel-inner {
    aspect-ratio: 1.8/1;
    object-fit: cover;
  }
}